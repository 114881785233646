<template>
  <div class="page-container">
    <div class="top">
      <div class="shadow-left">
        <img src="../assets/pic/shadow-left.png" />
      </div>
      <div class="banner">
        <div class="banner-content">
          <div class="content-top">
            <img src="../assets/pic/pic_premium_logo@2x.png" />
            <div class="more" @click="goMore">
              <span>更多服务</span>
              <img src="../assets/pic/icon_btn_small arrow@2x.png" />
            </div>
          </div>
          <h3>车险分期服务 即刻体验</h3>
          <h5>多种产品方案，申请简单，最快24小时到账</h5>
          <div class="login" @click="goBigtree">
            <span>介绍人/企业登录</span>
            <img src="../assets/pic/icon_btn_small arrow@2x.png" />
          </div>
        </div>
      </div>
      <div class="shadow-right">
        <img src="../assets/pic/shadow-right.png" />
      </div>
    </div>
    <div class="main">
      <div class="main-content">
        <div class="article">
          <span class="title">平台介绍</span>
          <span class="en">/PRODUCT</span>
        </div>
        <span class="text">
          “数智云商用车保险分期”业务是针对部分因投保车辆商业险所产生融资需求的企业所提供的一款分期产品。
          经数智云控、资金方及合作银行审批后，向符合授信准入条件的企业提供资金支持，借款人以分期的形式向贷款机构偿还资金。
        </span>
        <div class="btn">
          <div class="left">
            <div class="l-title">
              <span>我是投保企业</span>
            </div>
            <div class="l-img">
              <img src="../assets/pic/pic_premium_company@2x.png" />
            </div>
            <div class="l-tip">
              <span>我想申请车险分期</span>
            </div>
            <div class="l-btn" @click="callMe('enterprise')">
              <span>联系我们</span>
              <img src="../assets/pic/btn_premium_company arrow@2x.png" />
            </div>
          </div>
          <div class="right">
            <div class="r-title">
              <span>我是介绍人</span>
            </div>
            <div class="r-img">
              <img src="../assets/pic/pic_premium_company@2x(1).png" />
            </div>
            <div class="r-tip">
              <span>我想介绍更多企业进行车险分期</span>
            </div>
            <div class="r-btn" @click="callMe('introducer')">
              <span>联系我们</span>
              <img src="../assets/pic/btn_premium_company arrow@2x.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-two">
      <div class="flow">
        <div class="article">
          <span class="title">申请流程</span>
          <span class="en">/APPLICATION PROCESS</span>
        </div>
        <img src="../assets/pic/pic_premium_workflow@2x.png" />
      </div>
    </div>
    <div class="main-three">
      <div class="example">
        <div class="article">
          <span class="title">合作案例-物流车队</span>
          <span class="en">/COOPERATION CASES</span>
        </div>
        <div class="example-content">
          <div class="left">
            <p>企业案例-分期前：</p>
            <span>物流车队货物运输业务所用车辆，车险必须一年一投保</span>
            <span>批量车险投保占用大批量流动资金</span>
            <span>寻求其他融资模式获取资金，利率高</span>
          </div>
          <div class="center">
            <img src="../assets/pic/pic_premium_big arrow@2x.png" />
          </div>
          <div class="right">
            <p>企业案例-分期后：</p>
            <span>批量车险投保最低可0首付</span>
            <span>多种分期产品可供选择，寻求最适企业本身的分期方案</span>
            <span>车险分期利率低，定期还款无压力</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-content">
        <div class="top">
          <span>合作伙伴：</span>
          <img src="../assets/logo.png" />
        </div>
        <div class="bottom">
          <span>Copyright © 2022 福州数智云控信息技术有限公司. All rights reserved
            版权所有 |<img class="img" src="../assets/beian.png"> <a @click="goGov">闽公网安备 35011102350898号</a> | <a @click="goGov2">闽ICP备2021008164号-3</a></span
          >
        </div>
      </div>
    </div>
    <!-- 遮罩 -->
    <div class="mask" v-if="show"></div>
    <div class="popup" v-if="show">
      <div class="close">
        <span @click="cancel">X</span>
      </div>
      <div class="title">
        <span>您的身份</span>
      </div>
      <div class="user">
        <div
          :class="active == 1 ? 'item active' : 'item'"
          @click="userChange('1')"
        >
          投保企业
        </div>
        <div
          :class="active == 2 ? 'item active' : 'item'"
          @click="userChange('2')"
        >
          介绍人
        </div>
      </div>
      <div class="form">
        <el-form
          :model="handleForm"
          :rules="rules"
          ref="handleForm"
          label-width="100px"
          class="handleForm"
        >
          <el-form-item label="您的姓名" prop="name">
            <el-input
              v-model="handleForm.name"
              placeholder="请输入您的姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="phone">
            <el-input
              v-model="handleForm.phone"
              placeholder="请输入您的联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item label="填写备注" prop="content">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 3 }"
              v-model="handleForm.content"
              placeholder="您的任何疑问，都可向我们进行留言，将有专人联系您，为您解答"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn">
        <div class="btn-confirm" @click="submit">提交</div>
        <div class="btn-cancel" @click="cancel">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import { submitInfo } from "api/index";
export default {
  data() {
    // 校验手机号
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        return new Error("请输入您的手机号");
      } else {
        const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {
          //判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            //判断是否为11位手机号
            callback(new Error("手机号格式如:138xxxx8754"));
          } else {
            callback();
          }
        } else {
          callback(new Error("手机号必须是11位数字格式"));
        }
      }
    };
    var isName = (rule, value, callback) => {
      if (!value) {
        return new Error("请输入您的名字");
      } else {
        value = value.toString(); //转换成字符串
        if (value.length > 20) {
          //判断是否为11位手机号
          callback(new Error("请输入正确的名字"));
        } else {
          callback();
        }
      }
    };
    return {
      show: false,
      active: "1",
      handleForm: {
        identity: 1,
        name: undefined,
        phone: undefined,
        content: undefined,
        signature: undefined,
      },
      rules: {
        name: [
          { required: true, message: "请填写您的姓名", tigger: "blur" },
          { validator: isName, tigger: "blur" },
        ],
        phone: [
          { required: true, message: "请填写您的联系方式", tigger: "blur" },
          { validator: isMobileNumber, tigger: "blur" },
        ],
        content: [{ required: true, message: "请填写备注", tigger: "blur" }],
      },
    };
  },
  methods: {
    goBigtree() {
      window.open("https://szykdrs.cn");
    },
    goMore() {
      window.open("https://fzszyk.com");
    },
    goGov() {
      window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35011102350898");
    },
    goGov2() {
      window.open("https://beian.miit.gov.cn/?vt=4&pos=108&his=0#/Integrated/recordQuery")
    },
    callMe(v) {
      // enterprise
      this.show = true;
      if (v == "introducer") {
        this.active = "2";
        this.handleForm.identity = 2;
      } else {
        this.active = "1";
        this.handleForm.identity = 1;
      }
      this.handleForm.name = undefined;
      this.handleForm.phone = undefined;
      this.handleForm.content = undefined;
      this.$nextTick(() => {
        this.$refs["handleForm"].resetFields();
      });
    },
    userChange(v) {
      this.active = v;
      this.handleForm.identity = v == 1 ? 1 : 2;
    },
    submit() {
      this.$refs["handleForm"].validate((valid) => {
        if (valid) {
          let form = {
            identity: this.handleForm.identity,
            name: this.handleForm.name,
            phone: this.handleForm.phone,
          };
          this.handleForm.signature = this.$md5(
            this.$md5(JSON.stringify(form))
          );
          submitInfo(this.handleForm).then((res) => {
            if (res.status == 200) {
              this.$message({
                type: "success",
                message: "提交成功！",
              });
            }
            this.show = false;
          });
        } else {
          this.$message({
            type: "warning",
            message: "请在信息填写完整后再提交",
            duration: 3000,
          });
        }
      });
    },
    cancel() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  line-height: 0 !important;
}
.page-container {
  font-family: "SYHTCN";
  min-width: 1350px;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  .top {
    width: 100%;
    background-color: #232372;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .shadow-left,
    .shadow-right {
      height: 348px;
      width: auto;
      position: relative;
      bottom: -2px;
      img {
        height: 100%;
        width: auto;
      }
    }
    .banner {
      width: 1200px;
      height: 348px;
      background: url("../assets/pic/banner.png") center center no-repeat;
      background-size: auto;
      margin: auto;
      .banner-content {
        width: 1000px;
        margin: auto;
        box-sizing: border-box;
        padding: 30px 0 20px;
        .content-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          img {
            width: 173px;
            height: auto;
          }
          .more {
            width: 110px;
            height: 35px;
            background: rgba(169, 173, 253, 0.3);
            border-radius: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            span {
              font-size: 16px;
              font-weight: 400;
              line-height: 16px;
              color: #ffffff;
            }
            img {
              width: 12px;
              height: 12px;
            }
          }
        }
        h3 {
          font-size: 44px;
          font-weight: bold;
          color: #ffffff;
          margin: 30px 0 20px 3px;
        }
        h5 {
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          margin: 0 0 15px 10px;
        }
        .login {
          width: 245px;
          height: 66px;
          background: url("../assets/pic/btn_banner_blue@2x.png") no-repeat;
          background-size: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          left: -8px;
          cursor: pointer;
          span {
            font-size: 20px;
            font-weight: 500;
            line-height: 20px;
            color: #ffffff;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
  .main {
    width: 100%;
    background-color: #0a0a5c;
    padding: 30px 0 40px;
    .main-content {
      width: 1000px;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .article {
        width: 100%;
        margin-bottom: 40px;
        .title {
          font-size: 42px;
          font-weight: 500;
          color: #ffffff;
          text-shadow: rgba(12,123,250,.5) 0 0 8px;
        }
        .en {
          font-size: 20px;
          margin-left: 12px;
          font-family: 'SYHT';
          color: #fefefe;
          opacity: 0.5;
        }
      }
      .text {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 35px;
        line-height: 30px;
        text-indent: 2em;
      }
      .btn {
        width: 100%;
        box-sizing: border-box;
        padding: 0 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left,
        .right {
          width: 400px;
          background: rgba(54, 20, 192, 0.3);
          border: 4px solid #3614c0;
          border-radius: 20px;
          box-sizing: border-box;
          padding: 30px 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          &:hover {
            box-shadow: 0 0 10px 15px rgba(12, 12, 12, 0.1);
            transform: translateX(-10px);
            transition: all 0.3s;
          }
        }
        .left {
          .l-title {
            span {
              font-size: 26px;
              font-weight: bold;
              color: #ffffff;
            }
          }
          .l-img {
            width: 69px;
            height: 126px;
            margin: 20px 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .l-tip {
            margin-bottom: 20px;
            span {
              font-size: 20px;
              color: #ffffff;
            }
          }
          .l-btn {
            width: 170px;
            height: 50px;
            background: url("../assets/pic/btn_premium_company@2x.png")
              no-repeat;
            background-size: 100%;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              font-size: 20px;
              line-height: 20px;
              color: #ffffff;
            }
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
        .right {
          .r-title {
            span {
              font-size: 26px;
              font-weight: bold;
              color: #ffffff;
            }
          }
          .r-img {
            width: 79px;
            height: 119px;
            margin: 23px 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .r-tip {
            margin-bottom: 20px;
            span {
              font-size: 20px;
              color: #ffffff;
            }
          }
          .r-btn {
            width: 170px;
            height: 50px;
            background: url("../assets/pic/btn_premium_company@2x.png")
              no-repeat;
            background-size: 100%;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              font-size: 20px;
              line-height: 20px;
              color: #ffffff;
            }
            img {
              width: 16px;
              height: 16px;
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .main-two {
    width: 100%;
    background-color: #070751;
    .flow {
      width: 1000px;
      margin: auto;
      box-sizing: border-box;
      padding: 40px 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .article {
        width: 100%;
        margin-bottom: 40px;
        .title {
          font-size: 42px;
          font-weight: 500;
          color: #ffffff;
          text-shadow: rgba(12,123,250,.5) 0 0 8px;
        }
        .en {
          font-size: 20px;
          margin-left: 12px;
          font-family: 'SYHT';
          color: #fefefe;
          opacity: 0.5;
        }
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .main-three {
    width: 100%;
    background-color: #0a0a5c;
    box-sizing: border-box;
    padding: 40px 0 45px;
    .example {
      width: 1000px;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .article {
        width: 100%;
        margin-bottom: 40px;
        .title {
          font-size: 42px;
          font-weight: 500;
          color: #ffffff;
          text-shadow: rgba(12,123,250,.5) 0 0 8px;
        }
        .en {
          font-size: 20px;
          margin-left: 12px;
          font-family: 'SYHT';
          color: #fefefe;
          opacity: 0.5;
        }
      }
      .example-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          width: 450px;
          height: 330px;
          background: url("../assets/pic/pic_premium_front@2x.png") no-repeat;
          background-size: 100%;
          box-sizing: border-box;
          padding: 60px 35px;
          p {
            display: block;
            width: 241px;
            height: 55px;
            line-height: 55px;
            font-size: 24px;
            text-align: center;
            font-weight: bold;
            color: #ffffff;
            background: url("../assets/pic/pic_premium_front tag@2x.png")
              no-repeat;
            background-size: 100%;
            position: relative;
            left: -36px;
            top: 0;
          }
          span {
            display: block;
            margin-top: 20px;
            font-size: 16px;
            color: #ffffff;
            position: relative;
            &:nth-of-type(1) {
              margin-top: 30px;
            }
            &::before {
              display: block;
              content: "";
              width: 4px;
              height: 4px;
              background: #ffffff;
              opacity: 0.5;
              border-radius: 50%;
              position: absolute;
              left: -14px;
              top: 10px;
            }
          }
        }
        .center {
          width: 49px;
          height: 38px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .right {
          width: 450px;
          height: 330px;
          background: url("../assets/pic/pic_premium_back@2x.png") no-repeat;
          background-size: 100%;
          box-sizing: border-box;
          padding: 60px 35px;
          p {
            display: block;
            width: 241px;
            height: 55px;
            line-height: 55px;
            text-align: center;
            font-size: 24px;
            font-weight: bold;
            color: #ffffff;
            background: url("../assets/pic/pic_premium_back tag@2x.png")
              no-repeat;
            background-size: 100%;
            position: relative;
            left: -35px;
            top: 0;
          }
          span {
            display: block;
            margin-top: 20px;
            font-size: 16px;
            color: #ffffff;
            position: relative;
            &:nth-of-type(1) {
              margin-top: 30px;
            }
            &::before {
              display: block;
              content: "";
              width: 4px;
              height: 4px;
              background: #ffffff;
              opacity: 0.5;
              border-radius: 50%;
              position: absolute;
              left: -14px;
              top: 10px;
            }
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    background-color: #040434;
    box-sizing: border-box;
    padding: 30px 0 40px;
    .footer-content {
      width: 1000px;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .top {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        background-color: #040434;
        span {
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
        }
        img {
          width: 169px;
          height: 60px;
        }
      }
      .bottom {
        span {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          .img {
            width: 15px;
            height: 15px;
            margin: 0 5px;
          }
        }
      }
    }
  }
  .mask {
    width: 100%;
    height: auto;
    background-color: black;
    opacity: 0.4;
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .popup {
    width: 600px;
    height: 460px;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 24px 70px 48px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    z-index: 101;
    top: calc(50% - 230px);
    left: calc(50% - 300px);
    .close {
      width: 100%;
      position: relative;
      span {
        font-size: 20px;
        position: absolute;
        right: -50px;
        top: -15px;
        cursor: pointer;
      }
    }
    .title {
      font-size: 20px;
      font-weight: 500;
      color: #222222;
      margin-bottom: 25px;
    }
    .user {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 23px;
      .item {
        width: 112px;
        height: 41px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #dddddd;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-left: 20px;
        cursor: pointer;
      }
      .active {
        background: url("../assets/pic/pic_window_select@2x.png") no-repeat;
        background-size: 100%;
        color: #1677ff;
        border: none;
      }
    }
    .form {
      width: 100%;
    }
    .btn {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        width: 100px;
        height: 40px;
        background-color: #fafafa;
        border-radius: 4px;
        border: 1px solid #dddddd;
        color: #666666;
        margin-left: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .btn-confirm {
        background-color: #215df0;
        color: #ffffff;
      }
    }
  }
}
</style>